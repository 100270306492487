.header-extra{
    justify-content: center;
    align-items: center;
    max-width: 100px;
    order: 1;
    display: flex;
    height: 60px;
    margin-left:30px;
    .theme-search-box{
      margin-right: 15px;
      &#PageSearchBoxTopNav{
        display: flex;
        .theme-search-input{
          margin-right: 0;
          border-bottom-left-radius: 2px;
          border-top-left-radius: 2px;
          border: 1px solid #DDD;
          border-right: 0;
          padding-left: 10px;
          font-size: 14px;
          font-style: italic;
          display: none;
        }
        .theme-search-submit{
          color: #FFF;
        //   transition: color $transition-linear;
          cursor: pointer;
          line-height: 1;
          font-size: 14px;
          text-transform: uppercase;
          white-space: nowrap;
            &:before {
              font-family: FontAwesome;
              content: "\f002";
              font-size: 14px;
              width: 30px;
              text-align: center;
              margin-right: 5px;
            //   transition: color $transition-linear;
          }
          &:hover{
            color: $accent-color;
            cursor: pointer;
          }
          &:hover:before {
              color: $accent-color;
          }

        }
      }
      .theme-search-submit{
        font-size: 0;
        &:after{
          display: inline-block;
          position: relative;
          font-size: 16px;
        }
      }
    }
  }
  #topNav .theme-main-nav .theme-nav {
      display: flex;
      align-items: center;
  }
  .theme-nav-style-slide .theme-nav-title {
      margin-top: 0;
  }
  .theme-nav-style-slide .theme-slide-nav-toggle {
      margin-top: 0;
  }
  #topNav .theme-nav-style-dropdown .theme-nav-more > a:after {
      top: auto;
  }